// services/getEnrolledFaceImage.js
const API_BASE_URL = process.env.REACT_APP_SEARCH_API_URL;

export const getEnrolledFaceImage = async (faceId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/enrolled-face-image/${faceId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch enrolled face image');
    }
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  } catch (error) {
    console.error('Error fetching enrolled face image:', error);
    throw error;
  }
};