// services/addStream.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_SEARCH_API_URL;

export const addStream = async (filename, category) => {
  try {
      const response = await axios.post(
          `${API_URL}/add_stream`,
          { filename },  // Request body
          { params: { category } }  // Query parameters
      );
      return response.data;
  } catch (error) {
      console.error('Error adding stream:', error);
      throw error;
  }
};
