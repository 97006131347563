import React from 'react';

export default function Numbers() {
  return (
    <React.Fragment>
      <>
        <section className="position-relative py-20 bg-secondary-dark overflow-hidden" style={{ backgroundImage: 'url("flex-assets/elements/pattern-dark.png")' }}>
          <div className="position-absolute top-0 start-0 h-100 w-100" style={{ background: 'radial-gradient(50% 50% at 50% 50%, rgba(42, 51, 66, 0) 0%, #2A3342 100%)' }} />
          <div className="position-relative container" style={{ zIndex: 1 }}>
            <div className="mb-16 text-center mw-md-4xl mx-auto">
              {/* <span className="badge mb-4 bg-success-dark text-success text-uppercase shadow">Numbers</span> */}
              <h2 className="font-heading mb-4 fs-5 text-white" style={{ letterSpacing: '-1px' }}>We believe in the power of data</h2>
              <p className="mb-0 fs-9 fw-medium text-secondary-light">Unlock the full potential of your video library with AinuVision's powerful AI-driven search and processing capabilities.</p>
            </div>
            <div className="row g-16">
              <div className="col-12 col-md-4 col-lg-4">
                <div className="text-center">
                <h2 className="mb-2 font-heading fs-5 text-white" style={{ letterSpacing: '-1px' }}>&lt; 1 sec</h2>
                  <p className="mb-0 fs-9 text-secondary-light fw-medium">To retrieve relevant video fragments</p>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                <div className="text-center">
                  <h2 className="mb-2 font-heading fs-5 text-white" style={{ letterSpacing: '-1px' }}>7+</h2>
                  <p className="mb-0 fs-9 text-secondary-light fw-medium">Supported languages</p>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                <div className="text-center">
                  <h2 className="mb-2 font-heading fs-5 text-white" style={{ letterSpacing: '-1px' }}>160 secs</h2>
                  <p className="mb-0 fs-9 text-secondary-light fw-medium">To process 4 hours video</p>
                </div>
              </div>
              {/* <div className="col-12 col-md-6 col-lg-3">
                <div className="text-center">
                  <h2 className="mb-2 font-heading fs-5 text-white" style={{ letterSpacing: '-1px' }}>3.500</h2>
                  <p className="mb-0 fs-9 text-secondary-light fw-medium">Unique Users</p>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

