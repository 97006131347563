// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import Home from './pages/Home';
import Search from './pages/Search';
import Downloaded from './pages/Downloaded';
import Settings from './pages/Settings';
import Login from './pages/Login';
import { isAuthenticated } from './services/authService';
import './App.css';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import Landing from './pages/Landing';
import PageLoader from './components/page-loader';
import FaceRecognition from './pages/FaceRecognition';

function PrivateRoute({ children }) {
  const auth = isAuthenticated();
  return auth ? children : <Navigate to="/login" />;
}

const WithBootstrap = ({ children }) => {
  const [cssLoaded, setCssLoaded] = useState(false);
  const cssFiles = ['/bootstrap.css', '/header.css'];

  useEffect(() => {
    const links = cssFiles.map(file => {
      const link = document.createElement('link');
      link.href = file;
      link.rel = 'stylesheet';
      link.type = 'text/css';
      document.head.appendChild(link);
      return link;
    });

    const checkCssLoaded = () => {
      const allLoaded = links.every(link => link.sheet && link.sheet.cssRules.length > 0);
      if (allLoaded) {
        setCssLoaded(true);
      } else {
        setTimeout(checkCssLoaded, 100);
      }
    };

    checkCssLoaded();

    return () => links.forEach(link => document.head.removeChild(link));
  }, []);

  if (!cssLoaded) {
    return <PageLoader />;
  }

  return <>{children}</>;
};

function App() {
  const [resetTrigger, setResetTrigger] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(isAuthenticated());
  const [searchSettings, setSearchSettings] = useState({ matches: 20, confidence: 0.6 });

  useEffect(() => {
    setIsLoggedIn(isAuthenticated());
    // Load saved search settings from localStorage
    const savedSettings = localStorage.getItem('searchSettings');
    if (savedSettings) {
      setSearchSettings(JSON.parse(savedSettings));
    }
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleSearchSettingsChange = (newSettings) => {
    setSearchSettings(newSettings);
    localStorage.setItem('searchSettings', JSON.stringify(newSettings));
  };

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <Routes>
          <Route path="/" element={<WithBootstrap><Landing /></WithBootstrap>} />
          <Route path="/login" element={<WithBootstrap><Login onLoginSuccess={handleLogin} /></WithBootstrap>} />
          <Route path="/home" element={
            <PrivateRoute>
              <Home
                resetTrigger={resetTrigger}
                isProcessing={isProcessing}
                setIsProcessing={setIsProcessing}
                setResetTrigger={setResetTrigger}
                searchSettings={searchSettings}
              />
            </PrivateRoute>
          } />
          <Route path="/face-recognition" element={
            <PrivateRoute>
              <FaceRecognition
                isProcessing={isProcessing}
                setResetTrigger={setResetTrigger}
              />
            </PrivateRoute>
          } />
          <Route path="/search" element={
            <PrivateRoute>
              <Search
                isProcessing={isProcessing}
                setResetTrigger={setResetTrigger}
                searchSettings={searchSettings}
              />
            </PrivateRoute>
          } />
          <Route path="/downloaded" element={
            <PrivateRoute>
              <Downloaded
                isProcessing={isProcessing}
                setResetTrigger={setResetTrigger}
              />
            </PrivateRoute>
          } />
          <Route path="/settings" element={
            <PrivateRoute>
              <Settings
                isProcessing={isProcessing}
                setResetTrigger={setResetTrigger}
                searchSettings={searchSettings}
                onSearchSettingsChange={handleSearchSettingsChange}
              />
            </PrivateRoute>
          } />
        </Routes>
      </Router>
    </I18nextProvider>
  );
}

export default App;