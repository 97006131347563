import React, { useState, useCallback } from 'react';
import SearchHeader from './SearchHeader';
import VideoResults from './VideoResults';
import { searchQuery } from '../services/search';
import { useTranslation } from 'react-i18next';

function SearchSection({ searchSettings }) {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [category, setCategory] = useState('');
  const [searchResults, setSearchResults] = useState({});
  const [foundNames, setFoundNames] = useState([]);
  const [detectedLanguage, setDetectedLanguage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasSearched, setHasSearched] = useState(false);
  
  const categories = ['Filmlər', 'Verilişlər', 'Xəbərlər', 'Cizgi Filmləri', 'Konsertlər', 'İdman'];

  const handleSearch = useCallback(async () => {
    // Clear previous errors and results
    setError(null);
    setSearchResults({});
    setFoundNames([]);
    setHasSearched(true);

    // Check if search text is empty
    if (!searchText.trim()) {
      setError(t('searchSection.emptySearchError'));
      return;
    }

    // Check if category is selected
    if (!category) {
      setError(t('searchSection.noCategoryError'));
      return;
    }

    setIsLoading(true);

    try {
      const result = await searchQuery(searchText, category, searchSettings);
      setSearchResults(result.index_map);
      setDetectedLanguage(result.language);
      setFoundNames(result.found_names || []); // Set found names from the API response
      console.log('Detected language:', result.language);
      console.log('Found names:', result.found_names);
    } catch (error) {
      console.error('Error during search:', error);
      if (error.response && error.response.status === 404) {
        const translatedCategory = t(`categories.${category}`);
        setError(t('searchSection.folderNotFoundError', { category: translatedCategory }));
      } else {
        setError(t('searchSection.generalSearchError'));
      }
    } finally {
      setIsLoading(false);
    }
  }, [searchText, category, searchSettings, t]);

  const totalFragments = Object.values(searchResults).reduce((acc, frames) => acc + frames.length, 0);

  const shouldShowNoResults = !hasSearched || (hasSearched && !isLoading && totalFragments === 0 && !error);

  return (
    <div className="search-section">
      <SearchHeader
        handleSearch={handleSearch}
        setSearchText={setSearchText}
        setCategory={setCategory}
        searchText={searchText}
        category={category}
        resultCount={totalFragments}
        isLoading={isLoading}
      />
      <div className="search-content">
        {error && <div className="error-message">{error}</div>}
        {!hasSearched && (
          <div className="initial-state">
            <svg width="130" height="130" viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M77.1875 12.1875C54.795 12.1875 36.5625 30.42 36.5625 52.8125C36.5625 62.5422 39.975 71.4594 45.7032 78.4591L13.3291 110.825L19.175 116.675L51.545 84.3009C58.7744 90.2279 67.8392 93.4577 77.1875 93.4375C99.58 93.4375 117.813 75.205 117.813 52.8125C117.813 30.42 99.58 12.1875 77.1875 12.1875ZM77.1875 20.3125C95.1844 20.3125 109.688 34.8156 109.688 52.8125C109.688 70.8094 95.1844 85.3125 77.1875 85.3125C59.1907 85.3125 44.6875 70.8094 44.6875 52.8125C44.6875 34.8156 59.1907 20.3125 77.1875 20.3125Z" fill="#868686"/>
            </svg>
            <p>{t('noResults')}</p>
          </div>
        )}
        {hasSearched && !isLoading && totalFragments === 0 && !error && (
          <div className="initial-state">
            <svg width="130" height="130" viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M77.1875 12.1875C54.795 12.1875 36.5625 30.42 36.5625 52.8125C36.5625 62.5422 39.975 71.4594 45.7032 78.4591L13.3291 110.825L19.175 116.675L51.545 84.3009C58.7744 90.2279 67.8392 93.4577 77.1875 93.4375C99.58 93.4375 117.813 75.205 117.813 52.8125C117.813 30.42 99.58 12.1875 77.1875 12.1875ZM77.1875 20.3125C95.1844 20.3125 109.688 34.8156 109.688 52.8125C109.688 70.8094 95.1844 85.3125 77.1875 85.3125C59.1907 85.3125 44.6875 70.8094 44.6875 52.8125C44.6875 34.8156 59.1907 20.3125 77.1875 20.3125Z" fill="#868686"/>
            </svg>
            <p>{t('noResults')}</p>
          </div>
        )}
        {hasSearched && totalFragments > 0 && (
          <VideoResults
            results={searchResults}
            isLoading={isLoading}
            category={category}
            foundNames={foundNames}
          />
        )}
      </div>
    </div>
  );
}

export default SearchSection;