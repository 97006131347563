import React from 'react';
import LanguageSwitcher from '../components/LanguageSwitcher';
import Sidebar from '../layouts/Sidebar';
import SearchSettings from '../components/SearchSettings';
import '../styles/SearchSettings.css';
import { useTranslation } from 'react-i18next';


function Settings({ isProcessing, setResetTrigger, searchSettings, onSearchSettingsChange }) {
  const { t } = useTranslation();
  const handleHomeClick = () => {
    if (!isProcessing) {
      setResetTrigger(prev => prev + 1);
    }
  };

  return (
    <div className='app'>
      <Sidebar onHomeClick={handleHomeClick} disabled={isProcessing} />
      <div className="main-content">
        <div className="settings-page">
          <h1 className="settings-title">{t('settings')}</h1>
          <div className="settings-container">
            <LanguageSwitcher />
            <SearchSettings 
              initialSettings={searchSettings} 
              onSettingsChange={onSearchSettingsChange} 
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;