// pages/Login.js
import React from 'react';
import LoginForm from '../components/LoginForm';
import '../styles/Login.css';
import { useTranslation } from 'react-i18next';

function Login({ onLoginSuccess }) {
  const { t } = useTranslation();
  return (
    <section className="position-relative py-32 bg-secondary-dark overflow-hidden" style={{ backgroundImage: 'url("flex-assets/elements/pattern-dark.png")', minHeight: '100vh' }}>
      <div className="position-absolute top-0 start-0 h-100 w-100" style={{ background: 'radial-gradient(50% 50% at 50% 50%, rgba(42, 51, 66, 0) 0%, #2A3342 100%)' }} />
      <div className="position-relative container" style={{ zIndex: 1 }}>
        <div className="mb-7 text-center mw-md mx-auto">
          <div className="mb-6 d-flex align-items-center justify-content-center mx-auto" style={{ width: 64, height: 64 }}>
            <img className="img-fluid" style={{ height: 64 }} src="logo_white_no_text.svg" alt="logo" />
          </div>
          <h2 className="font-heading mb-4 fs-7 text-white">{t('login.loginPage')}</h2>
        </div>
        <LoginForm onLoginSuccess={onLoginSuccess} />
      </div>
    </section>
  );
}

export default Login;