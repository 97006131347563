import React from "react";

export default function Cta() {
  return (
    <section
      className="position-relative py-28 bg-secondary-dark overflow-hidden"
      style={{
        backgroundImage: "url('flex-assets/elements/pattern-dark.png')",
      }}
    >
      <div
        className="position-absolute top-0 start-0 h-100 w-100"
        style={{
          background:
            "radial-gradient(50% 50% at 50% 50%, rgba(42, 51, 66, 0) 0%, #2A3342 100%)",
        }}
      ></div>
      <img
        className="position-absolute top-0 start-0 mt-12 ms-12"
        src="flex-assets/elements/dots-blue.svg"
        alt="dots"
      />
      <img
        className="position-absolute bottom-0 end-0 mb-12 me-12"
        src="flex-assets/elements/dots-blue.svg"
        alt="dots"
      />
      <div className="container">
        <div
          className="position-relative mw-4xl mx-auto text-center"
          style={{ zIndex: 10 }}
        >
          <h2
            className="mb-4 fs-6 font-heading text-white"
            style={{ letterSpacing: "-1px" }}
          >
            Skip manual tagging and find fragments instantly!
          </h2>
          <p className="mb-6 fs-9 fw-medium text-secondary-light">
            Contact us to discuss cloud-based options and custom solutions
            tailored to your specific workload requirements, ensuring optimal
            performance and cost-effectiveness for your organization.
          </p>
          <div className="row justify-content-center">
            <div className="col col-md-auto">
              <a
                className="btn btn-success w-100 px-7 py-4 fs-10 fw-medium text-success-light rounded shadow"
                href="https://www.ainuverse.com"
                target="_blank"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

{
  /* <section className="position-relative py-32 bg-secondary-dark overflow-hidden" style={{ backgroundImage: 'url("flex-assets/elements/pattern-dark.png")' }}>
      <div className="position-absolute top-0 start-0 h-100 w-100" style={{ background: 'radial-gradient(50% 50% at 50% 50%, rgba(42, 51, 66, 0) 0%, #2A3342 100%)' }} />
      <div className="container">
        <div className="position-relative" style={{ zIndex: 10 }}>
          <div className="row align-items-xl-center g-16">
            <div className="col-12 col-md-6">
              <div className="mw-md-md">
                <h2 className="mb-8 fs-5 font-heading text-white" style={{ letterSpacing: '-1px' }}>Join 6,000+ startups growing with Flex</h2>
                <ul className="mb-8 list-unstyled">
                  <li className="mb-4 d-flex align-items-start">
                    <img className="mt-1 me-3" src="flex-assets/elements/checkbox-green.svg" alt />
                    <p className="mb-0 fs-9 fw-medium text-secondary-light">Mauris pellentesque congue libero nec</p>
                  </li>
                  <li className="mb-4 d-flex align-items-start">
                    <img className="mt-1 me-3" src="flex-assets/elements/checkbox-green.svg" alt />
                    <p className="mb-0 fs-9 fw-medium text-secondary-light">Suspendisse mollis tincidunt</p>
                  </li>
                  <li className="d-flex align-items-start">
                    <img className="mt-1 me-3" src="flex-assets/elements/checkbox-green.svg" alt />
                    <p className="mb-0 fs-9 fw-medium text-secondary-light">Praesent varius justo vel justo pulvinar</p>
                  </li>
                </ul>
                <div className="row g-4">
                  <div className="col-12 col-md-auto"><a className="btn btn-dark-light w-100 px-7 py-4 fs-10 fw-medium text-light rounded shadow" href="#">Learn More</a></div>
                  <div className="col-12 col-md-auto"><a className="btn btn-success w-100 px-7 py-4 fs-10 fw-medium text-success-light rounded shadow" href="#">Get Started</a></div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="row justify-content-center">
                <div className="col-auto">
                  <div className="position-relative">
                    <img className="position-absolute top-0 end-0 mt-n14 me-n14" style={{ zIndex: 20 }} src="flex-assets/elements/circle-orange.svg" alt />
                    <img className="position-absolute bottom-0 start-0 ms-n12 mb-n10" src="flex-assets/elements/dots-blue.svg" alt />
                    <img className="position-relative img-fluid" src="flex-assets/images/cta/work.png" alt="z-index: 10;" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</section> */
}
