// pages/FaceRecognition.js
import React from 'react';
import FaceRecognitionComponent from '../components/FaceRecognitionComponent';
import '../styles/FaceRecognition.css';
import Sidebar from '../layouts/Sidebar';

function FaceRecognition({ isProcessing, setResetTrigger }) {
  const handleHomeClick = () => {
    if (!isProcessing) {
      setResetTrigger(prev => prev + 1);
    }
  };

  return (
    <div className='app'>
      <Sidebar onHomeClick={handleHomeClick} disabled={isProcessing} />
      <div className="main-content">
        <div className="face-recognition-page">
          <FaceRecognitionComponent />
        </div>
      </div>
    </div>
  );
}

export default FaceRecognition;