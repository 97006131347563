// services/processVideo.js
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_SEARCH_API_URL;

export const processVideo = async (category, filename, frameNumbers, foundNames) => {
    const baseFilename = filename.split('/').pop();
    try {
      const response = await axios.post(
        `${API_BASE_URL}/process-fragment`,
        {
          filename: baseFilename,
          frame_numbers: frameNumbers,
          found_names: foundNames
        },
        {
          params: { category },
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );
      return response.data.fragments; // Assuming the API still returns fragments in this structure
    } catch (error) {
      console.error('Error processing video:', error);
      throw new Error('Failed to process video');
    }
  };