// services/getUploadedFiles.js
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_SEARCH_API_URL;

export const getUploadedFiles = async () => {
  const response = await fetch(`${API_BASE_URL}/uploaded-videos`);
  if (!response.ok) {
    throw new Error('Failed to fetch uploaded videos');
  }
  return response.json();
};

export const removeAllUploadedFiles = async () => {
  const response = await fetch(`${API_BASE_URL}/remove-all-uploaded-files`, {
    method: 'POST',
  });
  if (!response.ok) {
    throw new Error('Failed to remove all uploaded files');
  }
  return response.json();
};

export const removeUploadedFile = async (category, filename) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/remove-file`,
      null,  // No body data
      {
        params: { 
          category,
          filename
        },
        headers: {
          'Content-Type': 'application/json',  // Changed from 'multipart/form-data'
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error removing file:', error);
    throw new Error('Failed to remove file');
  }
};