import React, { useState, useEffect } from 'react';
import '../styles/SearchSettings.css';
import { useTranslation } from 'react-i18next';

const SearchSettings = ({ initialSettings, onSettingsChange }) => {
  const { t } = useTranslation();
  const [matches, setMatches] = useState(initialSettings.matches);
  const [confidence, setConfidence] = useState(initialSettings.confidence);

  useEffect(() => {
    onSettingsChange({ matches, confidence });
  }, [matches, confidence, onSettingsChange]);

  const handleMatchesChange = (event) => {
    const value = Math.min(Math.max(parseInt(event.target.value) || 0, 1), 20);
    setMatches(value);
  };

  const handleConfidenceChange = (event) => {
    const value = parseFloat(event.target.value);
    setConfidence(value);
  };

  return (
    <div className="search-settings">
      <h3>{t('searchSettings')}</h3>
      <div className="settings-container">
        <div className="setting-item">
          <label htmlFor="matches">{t('numberOfMatches')} (1-20)</label>
          <input
            id="matches"
            type="number"
            min="1"
            max="20"
            value={matches}
            onChange={handleMatchesChange}
          />
        </div>
        <div className="setting-item">
          <label htmlFor="confidence">{t('confidence')} (0-1)</label>
          <input
            id="confidence"
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={confidence}
            onChange={handleConfidenceChange}
          />
          <span className="confidence-value">{confidence.toFixed(2)}</span>
        </div>
      </div>
    </div>
  );
};

export default SearchSettings;