import React from "react";

export default function Footer() {
  return (
    // <React.Fragment>
    //   <section className="position-relative pt-20 bg-secondary-dark overflow-hidden" style={{ backgroundImage: 'url("flex-assets/elements/pattern-dark.png")' }}>
    //     <div className="position-absolute top-0 start-0 h-100 w-100" style={{ background: 'radial-gradient(50% 50% at 50% 50%, rgba(42, 51, 66, 0) 0%, #2A3342 100%)' }} />
    //     <div className="position-relative container mb-6" style={{ zIndex: 1 }}>
    //       <div className="row align-items-center">
    //         <div className="col-12 col-md-auto">
    //           <a href="#" className="text-decoration-none">
    //             <img className="logo-image img-fluid" src="logo_white_no_text.svg" alt="Logo" style={{ maxWidth: '150px' }} />
    //             <p className="mb-0 text-white fs-11 position-absolute" style={{
    //               left: '110px',
    //               bottom: '0',
    //               right: '0',
    //               textAlign: 'left'
    //             }}>
    //               Contact us to discuss cloud-based options and custom solutions tailored to your specific workload requirements.
    //             </p>
    //           </a>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="position-relative border-bottom border-light-dark" style={{ zIndex: 1 }} />
    //     <div className="position-relative container" style={{ zIndex: 1 }}>
    //       <div className="row align-items-center justify-content-between pt-12 pb-36">
    //         <div className="col-12 d-flex flex-wrap align-items-center">
    //           <p className="mb-0 fs-11 fw-medium text-secondary-light">© 2024 AinuVision. All rights reserved.</p>
    //           <div className="ms-auto d-flex align-items-center">
    //             <a href="https://www.ainuverse.com" target="_blank" rel="noopener noreferrer" className="text-white text-decoration-none fs-11 me-4">
    //               www.ainuverse.com
    //             </a>
    //             <a href="https://www.linkedin.com/company/ainuverse" target="_blank" rel="noopener noreferrer" className="nav-link p-0">
    //               <svg width={32} height={32} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                 <path d="M19.2 3H4.8C3.81 3 3 3.81 3 4.8V19.2C3 20.19 3.81 21 4.8 21H19.2C20.19 21 21 20.19 21 19.2V4.8C21 3.81 20.19 3 19.2 3ZM8.4 18.3H5.7V10.2H8.4V18.3ZM7.05 8.67C6.15 8.67 5.43 7.95 5.43 7.05C5.43 6.15 6.15 5.43 7.05 5.43C7.95 5.43 8.67 6.15 8.67 7.05C8.67 7.95 7.95 8.67 7.05 8.67ZM18.3 18.3H15.6V13.53C15.6 12.81 14.97 12.18 14.25 12.18C13.53 12.18 12.9 12.81 12.9 13.53V18.3H10.2V10.2H12.9V11.28C13.35 10.56 14.34 10.02 15.15 10.02C16.86 10.02 18.3 11.46 18.3 13.17V18.3Z" fill="#ffffff" />
    //               </svg>
    //             </a>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </section>
    // </React.Fragment>
    <section
      className="position-relative pt-10 bg-secondary-dark overflow-hidden"
      style={{
        backgroundImage: "url('flex-assets/elements/pattern-dark.png')",
      }}
    >
      <div
        className="position-absolute top-0 start-0 h-100 w-100"
        style={{
          background:
            "radial-gradient(50% 50% at 50% 50%, rgba(42, 51, 66, 0) 0%, #2A3342 100%)",
        }}
      />
      <div
        className="position-relative border-bottom border-light-dark"
        style={{ zIndex: 1 }}
      />
      <div className="position-relative container" style={{ zIndex: 1 }}>
        <div className="row align-items-center justify-content-between pt-2 pb-2">
          <div className="col-auto">
            <p className="mb-0 fs-11 fw-medium text-secondary-light">
              © 2024 AinuVision. All rights reserved.
            </p>
          </div>
          <div className="col-auto">
            <ul className="nav justify-content-md-end">
              <li className="nav-item"></li>
              <li className="nav-item"></li>
              <li className="nav-item"></li>
              <li className="nav-item">
                <a
                  className="nav-link p-4 fs-9 link-secondary-light"
                  href="https://www.linkedin.com/company/ainuverse"
                  target="_blank"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.2 3H4.8C3.81 3 3 3.81 3 4.8V19.2C3 20.19 3.81 21 4.8 21H19.2C20.19 21 21 20.19 21 19.2V4.8C21 3.81 20.19 3 19.2 3ZM8.4 18.3H5.7V10.2H8.4V18.3ZM7.05 8.67C6.15 8.67 5.43 7.95 5.43 7.05C5.43 6.15 6.15 5.43 7.05 5.43C7.95 5.43 8.67 6.15 8.67 7.05C8.67 7.95 7.95 8.67 7.05 8.67ZM18.3 18.3H15.6V13.53C15.6 12.81 14.97 12.18 14.25 12.18C13.53 12.18 12.9 12.81 12.9 13.53V18.3H10.2V10.2H12.9V11.28C13.35 10.56 14.34 10.02 15.15 10.02C16.86 10.02 18.3 11.46 18.3 13.17V18.3Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
