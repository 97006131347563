// src/services/authService.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_SEARCH_API_URL;

export const login = async (username, password) => {
    const response = await axios.post(`${API_URL}/api/login`, 
      `username=${username}&password=${password}`,
      {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      }
    );
    if (response.data.access_token) {
      localStorage.setItem('token', response.data.access_token);
    }
    return response.data;
  };
  
  export const logout = () => {
    localStorage.removeItem('token');
  };
  
  export const isAuthenticated = () => {
    return !!localStorage.getItem('token');
  };
