// services/checkAvailableStreams.js
import axios from 'axios';

const CHECK_STREAMS_URL = process.env.REACT_APP_SEARCH_API_URL;

export const checkAvailableStreams = async () => {
  try {
      const response = await axios.post(`${CHECK_STREAMS_URL}/check_available_streams`);
      return response.data;
  } catch (error) {
      console.error('Error checking available streams:', error);
      throw error;
  }
};