const API_BASE_URL = process.env.REACT_APP_SEARCH_API_URL;

export const getEnrolledFaces = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/enrolled-faces`);

    if (!response.ok) {
      throw new Error('Failed to fetch enrolled faces');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching enrolled faces:', error);
    throw error;
  }
};