const API_BASE_URL = process.env.REACT_APP_SEARCH_API_URL;

export const enrollFace = async (imageFile, name) => {
  const formData = new FormData();
  formData.append('file', imageFile);
  formData.append('name', name);

  try {
    const response = await fetch(`${API_BASE_URL}/enroll-face`, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to enroll face');
    }

    return await response.json();
  } catch (error) {
    console.error('Error enrolling face:', error);
    throw error;
  }
};