import React, { useState } from 'react';
import '../styles/SearchHeader.css';
import { useTranslation } from 'react-i18next';

function SearchHeader({
  handleSearch,
  setSearchText,
  setCategory,
  searchText,
  category,
  resultCount,
  isLoading
}) {
  const { t } = useTranslation(); 

  const [categoryError, setCategoryError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setCategoryError(false);
    if (!category) {
      setCategoryError(true);
      return;
    }
    handleSearch();
  };

  const categories = ['Filmlər', 'Verilişlər', 'Xəbərlər', 'Cizgi Filmləri', 'Konsertlər', 'İdman'];

  return (
    <header className="header">
      <form onSubmit={handleSubmit} className="search-container">
        <input
          type="text"
          placeholder={t('searchPlaceholder')}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          className="search-input"
        />
        <div className="button-group">
          <select
            id="category-select"
            className={`category-select ${categoryError ? 'error' : ''}`}
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="" disabled hidden>{t('categoryPlaceholder')}</option>
            {categories.map((cat) => (
              <option key={cat} value={cat}>
                {t(`categories.${cat}`)}
              </option>
            ))}
          </select>
          <button className="axtar-button" type="submit">
          <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="56" height="56" rx="8" fill="#FF46F8"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M30.9814 32.3075C29.2963 33.6538 27.1597 34.3039 25.0103 34.1241C22.861 33.9444 20.862 32.9484 19.424 31.3409C17.986 29.7333 17.2182 27.6361 17.2782 25.4801C17.3382 23.324 18.2214 21.2728 19.7466 19.7477C21.2717 18.2225 23.3229 17.3393 25.479 17.2793C27.635 17.2193 29.7322 17.9871 31.3398 19.4251C32.9473 20.8631 33.9433 22.8621 34.123 25.0114C34.3028 27.1608 33.6527 29.2974 32.3064 30.9825L38.7514 37.4262C38.8435 37.512 38.9173 37.6155 38.9686 37.7305C39.0198 37.8455 39.0474 37.9697 39.0496 38.0956C39.0518 38.2214 39.0287 38.3465 38.9815 38.4632C38.9344 38.5799 38.8642 38.686 38.7752 38.775C38.6861 38.864 38.5801 38.9342 38.4634 38.9814C38.3466 39.0285 38.2216 39.0517 38.0957 39.0495C37.9698 39.0472 37.8457 39.0197 37.7307 38.9684C37.6157 38.9172 37.5122 38.8433 37.4264 38.7512L30.9814 32.3075ZM21.0751 30.355C20.1577 29.4374 19.5328 28.2685 19.2795 26.9959C19.0262 25.7234 19.1558 24.4043 19.652 23.2053C20.1482 22.0064 20.9886 20.9815 22.0671 20.2601C23.1456 19.5387 24.4138 19.1532 25.7113 19.1523C27.0089 19.1514 28.2775 19.5352 29.357 20.2552C30.4365 20.9751 31.2783 21.9989 31.7761 23.1972C32.2739 24.3954 32.4053 25.7144 32.1537 26.9873C31.9021 28.2602 31.2788 29.4299 30.3626 30.3487L30.3564 30.355L30.3501 30.36C29.119 31.5882 27.4506 32.2776 25.7116 32.2767C23.9725 32.2757 22.3049 31.5846 21.0751 30.355Z" fill="white"/>
            </svg>
          </button>
        </div>
      </form>
    </header>
  );
}

export default SearchHeader;