// services/uploadService.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_SEARCH_API_URL;

export const searchQuery = async (query, category, settings = {}) => {
    try {
      const { matches = 20, confidence = 0.6 } = settings;
      const response = await axios.post(
        `${API_URL}/search`,
        query, // Send the query as a plain string
        {
          params: {
            K: matches,
            THRESHOLD: confidence,
            category // category is a query parameter
          },
          headers: {
            'Content-Type': 'text/plain', // Change content type to text/plain
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error during API call:', error);
      throw error;
    }
  };