// services/uploadService.js
import axios from 'axios';

const UPLOAD_URL = process.env.REACT_APP_SEARCH_API_URL;

export const uploadVideo = async (fileOrUrl, category) => {
  const formData = new FormData();
  if (typeof fileOrUrl === 'string') {
      // It's a YouTube URL
      formData.append('youtube_url', fileOrUrl);
  } else {
      // It's a file
      formData.append('file', fileOrUrl, fileOrUrl.name);
  }

  try {
      const response = await axios.post(
          `${UPLOAD_URL}/upload-video`,
          formData,
          {
              params: { category },  // Send category as a query parameter
              headers: {
                  'Content-Type': 'multipart/form-data',
              },
          }
      );
      return response.data;
  } catch (error) {
      console.error('Error uploading video:', error);
      throw error;
  }
};