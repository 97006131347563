import React, { useState, useEffect, useCallback } from 'react';
// import '../styles/VideoResults.css';
import '../styles/Downloaded.css';
import { getUploadedFiles, removeUploadedFile, removeAllUploadedFiles } from '../services/downloadedVideos';
import { useTranslation } from 'react-i18next';
import Sidebar from '../layouts/Sidebar';

const API_BASE_URL = process.env.REACT_APP_SEARCH_API_URL;

function VideoModal({ videoUrl, onClose }) {
  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Escape') {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div className="video-modal" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <button className="close-button" onClick={onClose}>X</button>
        </div>
        <video controls autoPlay>
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
}

function VideoItem({ video, onRemove }) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="video-item">
      <div className="thumbnail-container">
        <img
          src={`${API_BASE_URL}${video.thumbnail_url}`}
          alt={`Thumbnail for ${video.filename}`}
        />
        <button className="play-button" onClick={() => setIsModalOpen(true)}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 3L19 12L5 21V3Z" fill="white" />
          </svg>
        </button>
      </div>
      <div className="video-info">
        <p className="category">{t(`categories.${video.category}`)}</p>
        <h3>{video.filename}</h3>
        <button className="remove-button" onClick={() => onRemove(video.id, video.category)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <polyline points="3 6 5 6 21 6"></polyline>
            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
            <line x1="10" y1="11" x2="10" y2="17"></line>
            <line x1="14" y1="11" x2="14" y2="17"></line>
          </svg>
        </button>
      </div>
      {isModalOpen && (
        <VideoModal
          videoUrl={`${API_BASE_URL}${video.video_url}`}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
}

function Downloaded({ isProcessing, setResetTrigger }) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('axtaris');
  const [downloadedVideos, setDownloadedVideos] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const videos = JSON.parse(localStorage.getItem('downloadedVideos') || '[]');
    setDownloadedVideos(videos);

    setIsLoading(true);
    getUploadedFiles()
      .then(data => {
        setUploadedFiles(data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching uploaded videos:', error);
        setError('Failed to load uploaded videos. Please try again later.');
        setIsLoading(false);
      });
  }, []);

  const handleRemove = async (id, category) => {
    if (activeTab === 'axtaris') {
      const updatedVideos = downloadedVideos.filter(video => video.id !== id);
      setDownloadedVideos(updatedVideos);
      localStorage.setItem('downloadedVideos', JSON.stringify(updatedVideos));
    } else {
      try {
        await removeUploadedFile(category, id.split('/')[1]);
        const updatedFiles = uploadedFiles.filter(file => file.id !== id);
        setUploadedFiles(updatedFiles);
      } catch (error) {
        console.error('Error removing file:', error);
        setError('Failed to remove file. Please try again later.');
      }
    }
  };

  const handleRemoveAll = async () => {
    if (activeTab === 'axtaris') {
      setDownloadedVideos([]);
      localStorage.removeItem('downloadedVideos');
    } else {
      try {
        await removeAllUploadedFiles();
        setUploadedFiles([]);
      } catch (error) {
        console.error('Error removing all uploaded files:', error);
        setError('Failed to remove all uploaded files. Please try again later.');
      }
    }
  };

  const handleHomeClick = () => {
    if (!isProcessing) {
      setResetTrigger(prev => prev + 1);
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return <div className="loading">{t('downloadedPage.loading')}</div>;
    }

    if (error) {
      return <div className="error-message">{t('downloadedPage.errorMessage')}</div>;
    }

    const items = activeTab === 'axtaris' ? downloadedVideos : uploadedFiles;

    if (items.length === 0) {
      return (
        <div className="empty-state">
          <div className="empty-icon">
            <svg width="130" height="130" viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M105.625 16.25H24.375C19.8877 16.25 16.25 19.8877 16.25 24.375V105.625C16.25 110.112 19.8877 113.75 24.375 113.75H105.625C110.112 113.75 113.75 110.112 113.75 105.625V24.375C113.75 19.8877 110.112 16.25 105.625 16.25Z" stroke="#868686" strokeWidth="3" strokeLinejoin="round" />
              <path d="M10.8334 83.9583H40.625L46.0417 94.7917H83.9584L89.375 83.9583H119.167M113.75 97.5V70.4167M48.75 59.5833L65 75.8333M65 75.8333L81.25 59.5833M65 75.8333V32.5M16.25 97.5V70.4167" stroke="#868686" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
          <p>{t('downloadedPage.emptyState')}</p>
        </div>
      );
    }

    return (
      <>
        <p className="result-count">
          {t(`downloadedPage.${activeTab === 'axtaris' ? 'fragment' : 'video'}`)}{items.length}
        </p>
        <div className="video-grid">
          {items.map((item) => (
            <VideoItem
              key={item.id}
              video={item}
              onRemove={handleRemove}
            />
          ))}
        </div>
      </>
    );
  };

  return (
    <div className='app'>
      <Sidebar onHomeClick={handleHomeClick} disabled={isProcessing} />
      <div className="main-content">
        <div className="downloaded-page">
          <div className="tab-header">
            <div className="tabs">
              <button
                className={`tab ${activeTab === 'axtaris' ? 'active' : ''}`}
                onClick={() => setActiveTab('axtaris')}
              >
                {t('downloadedPage.searchResults')}
              </button>
              <button
                className={`tab ${activeTab === 'yuklenenler' ? 'active' : ''}`}
                onClick={() => setActiveTab('yuklenenler')}
              >
                {t('downloadedPage.uploadedVideos')}
              </button>
            </div>
            {(downloadedVideos.length > 0 || uploadedFiles.length > 0) && (
              <button className="remove-all-button" onClick={handleRemoveAll}>
                <span>{t('downloadedPage.removeAll')}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
              </button>
            )}
          </div>
          {renderContent()}
        </div>
      </div>
    </div>
  );
}

export default Downloaded;