// utils/fileHandlers.js

export const handleFolderSelect = async (setStatus, fileInputRef) => {
    if ('showDirectoryPicker' in window) {
      try {
        setStatus('Selecting folder...');
        const dirHandle = await window.showDirectoryPicker();
        setStatus('Folder selected: ' + dirHandle.name);
        const files = [];
        console.log('Files in the selected folder:');
        for await (const entry of dirHandle.values()) {
          if (entry.kind === 'file') {
            const file = await entry.getFile();
            console.log('- File:', file.name, '| Size:', file.size, 'bytes');
            files.push(file);
          }
        }
        setStatus(`Selected ${files.length} file(s) from folder`);
        return files;
      } catch (err) {
        if (err.name === 'AbortError') {
          setStatus('Folder selection cancelled');
        } else {
          console.error('Error selecting directory:', err);
          setStatus('Error selecting folder');
        }
        return [];
      }
    } else {
      fileInputRef.current.setAttribute('webkitdirectory', 'true');
      fileInputRef.current.click();
      return null; // The actual files will be handled by handleFileInputChange
    }
  };
  
  export const handleFileInputChange = (event, setStatus) => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
    //   setStatus(`Selected ${files.length} file(s)`);
      console.log('Selected files:');
      for (let file of files) {
        console.log('- File:', file.name, '| Size:', file.size, 'bytes', '| Type:', file.type, '| File Path:', file.webkitRelativePath);
      }
      return files;
    }
    return [];
  };