// pages/Search.js
import React from 'react';
import SearchSection from '../components/SearchSection';
import '../styles/Search.css';
import Sidebar from '../layouts/Sidebar';

function Search({ isProcessing, setResetTrigger, searchSettings }) {
  const handleHomeClick = () => {
    if (!isProcessing) {
      setResetTrigger(prev => prev + 1);
    }
  };

  return (
    <div className='app'>
      <Sidebar onHomeClick={handleHomeClick} disabled={isProcessing} />
      <div className="main-content">
      <div className="search-page">
        <SearchSection searchSettings={searchSettings} />
      </div>
      </div>
    </div>
  );
}

export default Search;