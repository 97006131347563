import React, { useState, useEffect, useCallback } from 'react';
import { processVideo } from '../services/processVideo';
import '../styles/VideoResults.css';
import DownloadButton from './DownloadButton';
import { useTranslation } from 'react-i18next';

const API_BASE_URL = process.env.REACT_APP_SEARCH_API_URL;

function VideoModal({ videoUrl, onClose }) {
    const handleKeyDown = useCallback((event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    }, [onClose]);
  
    useEffect(() => {
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, [handleKeyDown]);
  
    return (
      <div className="video-modal" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-header">
            <button className="close-button" onClick={onClose}>X</button>
          </div>
          <video controls autoPlay>
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    );
  }

  function VideoFragment({ fragment, filename, category, onPlay }) {
    return (
      <div className="video-fragment">
        <img
          src={`${API_BASE_URL}${fragment.thumbnail_url}`}
          alt={`Thumbnail for ${filename}`}
          onClick={() => onPlay(fragment.video_url)}
        />
        <p className="timestamp">
          {fragment.start_time} - {fragment.end_time}
        </p>
        <DownloadButton videoData={fragment} filename={filename} category={category} />
      </div>
    );
  }
  
  function VideoItem({ filename, fragments, category }) {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentVideoUrl, setCurrentVideoUrl] = useState('');
  
    const handlePlay = (videoUrl) => {
      setCurrentVideoUrl(`${API_BASE_URL}${videoUrl}`);
      setIsModalOpen(true);
    };
  
    return (
      <div className="video-item">
        <div className="video-info">
          <p className="category">{t(`categories.${category}`)}</p>
          <h3>{filename.split('/').pop()}</h3>
        </div>
        <div className="video-fragments">
          {fragments.map((fragment, index) => (
            <VideoFragment
              key={index}
              fragment={fragment}
              filename={filename}
              category={category}
              onPlay={handlePlay}
            />
          ))}
        </div>
        {isModalOpen && (
          <VideoModal
            videoUrl={currentVideoUrl}
            onClose={() => setIsModalOpen(false)}
          />
        )}
      </div>
    );
  }
  
  function VideoResults({ results, isLoading, category, foundNames }) {
    const { t } = useTranslation();
    const [processedResults, setProcessedResults] = useState({});
    const [noFragmentsFound, setNoFragmentsFound] = useState(false);
  
    useEffect(() => {
      const processResults = async () => {
        const processed = {};
        let fragmentsFound = false;
        for (const [fullPath, frameNumbers] of Object.entries(results)) {
          const filename = fullPath.split('/').pop();
          try {
            const fragments = await processVideo(category, filename, frameNumbers, foundNames);
            if (fragments.length > 0) {
              processed[filename] = fragments;
              fragmentsFound = true;
            }
          } catch (error) {
            console.error(`Error processing video ${filename}:`, error);
          }
        }
        setProcessedResults(processed);
        setNoFragmentsFound(!fragmentsFound);
      };
  
      if (!isLoading && Object.keys(results).length > 0) {
        processResults();
      }
    }, [results, isLoading, category, foundNames]);
  
    if (isLoading) {
      return <div className="loading">{t('loading')}</div>;
    }
  
    const totalVideos = Object.keys(results).length;
  
    if (totalVideos === 0) {
      return <div className="no-results"></div>;
    }
  
    if (noFragmentsFound) {
      return (
        <div className="video-results">
          <p className="result-count">{t('resultCount')} {totalVideos}</p>
          <p className="no-fragments-message">
            {foundNames.length > 0 
              ? t('noFragmentsFoundName', { name: foundNames.join(', ') })
              : t('noFragmentsFound')}
          </p>
        </div>
      );
    }
  
    return (
      <div className="video-results">
        <p className="result-count">{t('resultCount')} {totalVideos}</p>
        <div className="video-list">
          {Object.entries(processedResults).map(([filename, fragments]) => (
            <VideoItem
              key={filename}
              filename={filename}
              fragments={fragments}
              category={category}
            />
          ))}
        </div>
      </div>
    );
  }
  
  export default VideoResults;