import axios from 'axios';

const API_URL = process.env.REACT_APP_SEARCH_API_URL;

export const joinWaitlist = async (email) => {
  try {
    const response = await axios.post(`${API_URL}/api/join-waitlist`, { email });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('An error occurred while joining the waitlist');
  }
};