import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import WaitlistModal from "./waitListModal";

export default function Header() {
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const videoRef = useRef(null);
  const playButtonRef = useRef(null);

  const handlePlayVideo = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleVideoEnded = () => {
    setIsPlaying(false);
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <React.Fragment>
      <section className="position-relative bg-secondary-dark overflow-hidden">
        <div className="position-absolute top-0 start-0 h-100 w-100 radial-gradient-bg" />
        <div className="position-relative">
          <nav className="navbar navbar-dark navbar-expand-lg">
            <div className="container-fluid">
              <a className="navbar-brand" href="/">
                <img
                  className="logo-image img-fluid"
                  src="logo_white_no_text.svg"
                  alt="Logo"
                />
              </a>
              <div className="collapse navbar-collapse justify-content-center">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a className="nav-link link-light-light" href="#">
                      Overview
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary-light"
                      href="#features"
                      onClick={(e) => {
                        e.preventDefault();
                        scrollToSection("features");
                      }}
                    >
                      Features
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary-light"
                      href="#applications"
                      onClick={(e) => {
                        e.preventDefault();
                        scrollToSection("applications");
                      }}
                    >
                      Applications
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary-light"
                      href="#highlights"
                      onClick={(e) => {
                        e.preventDefault();
                        scrollToSection("highlights");
                      }}
                    >
                      Highlights
                    </a>
                  </li>
                </ul>
              </div>
              <div className="d-none d-lg-block">
                <div className="row align-items-center g-6">
                  <div className="col-auto">
                    <Link
                      to="/login"
                      className="btn btn-sm btn-success text-success-light shadow"
                    >
                      Log In
                    </Link>
                  </div>
                </div>
              </div>
              <div className="d-lg-none">
                <Link
                  to="/login"
                  className="btn btn-sm btn-success text-success-light shadow"
                >
                  Log In
                </Link>
                {/* <a className="btn navbar-burger p-0" href="#">
                  <svg
                    width="35"
                    height="35"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      className="text-light-dark"
                      width="32"
                      height="32"
                      rx="6"
                      fill="currentColor"
                    />
                    <path
                      className="text-secondary-light"
                      d="M7 12H25C25.2652 12 25.5196 11.8946 25.7071 11.7071C25.8946 11.5196 26 11.2652 26 11C26 10.7348 25.8946 10.4804 25.7071 10.2929C25.5196 10.1054 25.2652 10 25 10H7C6.73478 10 6.48043 10.1054 6.29289 10.2929C6.10536 10.4804 6 10.7348 6 11C6 11.2652 6.10536 11.5196 6.29289 11.7071C6.48043 11.8946 6.73478 12 7 12ZM25 15H7C6.73478 15 6.48043 15.1054 6.29289 15.2929C6.10536 15.4804 6 15.7348 6 16C6 16.2652 6.10536 16.5196 6.29289 16.7071C6.48043 16.8946 6.73478 17 7 17H25C25.2652 17 25.5196 16.8946 25.7071 16.7071C25.8946 16.5196 26 16.2652 26 16C26 15.7348 25.8946 15.4804 25.7071 15.2929C25.5196 15.1054 25.2652 15 25 15ZM25 20H7C6.73478 20 6.48043 20.1054 6.29289 20.2929C6.10536 20.4804 6 20.7348 6 21C6 21.2652 6.10536 21.5196 6.29289 21.7071C6.48043 21.8946 6.73478 22 7 22H25C25.2652 22 25.5196 21.8946 25.7071 21.7071C25.8946 21.5196 26 21.2652 26 21C26 20.7348 25.8946 20.4804 25.7071 20.2929C25.5196 20.1054 25.2652 20 25 20Z"
                      fill="currentColor"
                    />
                  </svg>
                </a> */}
              </div>
            </div>
          </nav>
          {/* <div className="d-none navbar-menu position-fixed top-0 start-0 bottom-0 w-75 mw-xs">
            <div className="navbar-backdrop position-fixed top-0 start-0 end-0 bottom-0 bg-dark" />
            <nav className="position-relative h-100 w-100 d-flex flex-column justify-content-between p-6 bg-secondary-dark overflow-auto">
              <div className="d-flex align-items-center">
                <a className="me-auto h4 mb-0 text-decoration-none" href="#">
                  <img
                    className="img-fluid"
                    src="flex-assets/logos/flex-light-green.svg"
                    alt=""
                  />
                </a>
                <a className="navbar-close text-secondary-light" href="#">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 18L18 6M6 6L18 18"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
              </div>
              <div className="py-16">
                <ul className="nav nav-pills flex-column">
                  <li className="nav-item">
                    <a
                      className="nav-link link-light-light bg-light-dark"
                      href="#"
                    >
                      Product
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link link-secondary-light" href="#">
                      Features
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link link-secondary-light" href="#">
                      Pricing
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link link-secondary-light" href="#">
                      Resources
                    </a>
                  </li>
                </ul>
              </div>
              <div className="row align-items-center g-6">
                <div className="col-12">
                  <a
                    className="fs-11 d-block w-100 link-secondary-light text-center fw-medium"
                    href="#"
                  >
                    Log In
                  </a>
                </div>
              </div>
            </nav>
          </div> */}
        </div>
        <div className="container">
          <div className="pt-12 pb-lg-28 position-relative">
            <div className="mb-16 text-center mw-md-3xl mx-auto">
              <h1 className="mb-6 font-heading fs-4 text-white">
                Revolutionary AI-Powered Video Search
              </h1>
              <p className="mb-8 fs-9 fw-medium text-secondary-light">
                AinuVision is a multimodal AI solution that transforms how
                companies manage and search their vast video libraries. Say
                goodbye to time-consuming manual tagging and inefficient
                filename searches.
              </p>
              <div className="row justify-content-center g-4">
                <div className="col-12 col-md-auto">
                  <button
                    className="btn btn-lg btn-success w-100 text-success-light shadow"
                    onClick={() => setIsModalOpen(true)}
                  >
                    Request a Demo
                  </button>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="position-relative col-auto">
                <img
                  className="position-absolute top-0 start-0 mt-n6 ms-n6"
                  src="flex-assets/elements/circle-orange.svg"
                  alt="circle"
                />
                <img
                  className="position-absolute bottom-0 end-0 me-n6 mb-n8"
                  src="flex-assets/elements/wave-blue.svg"
                  alt="wave"
                />
                <div className="position-relative overflow-hidden rounded-5 video-container">
                  <video
                    style={{ maxHeight: 532 }}
                    poster="flex-assets/images/headers/ainuvision_thumbnail.png"
                    ref={videoRef}
                    onEnded={handleVideoEnded}
                  >
                    <source
                      src="flex-assets/videos/ainuvision.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                  {!isPlaying && (
                    <button
                      className="play-button position-absolute top-50 start-50 translate-middle text-success link-success"
                      onClick={handlePlayVideo}
                      ref={playButtonRef}
                    >
                      <svg
                        width="36"
                        height="36"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M5 3L19 12L5 21V3Z" fill="white" />
                      </svg>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WaitlistModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </React.Fragment>
  );
}
