// components/FaceRecognitionComponent.js
import React, { useState, useRef, useEffect } from 'react';
import '../styles/FaceRecognition.css';
import { enrollFace } from '../services/enrollFace';
import { getEnrolledFaces } from '../services/getEnrolledFaces';
import { removeFace } from '../services/removeFace';
import { getEnrolledFaceImage } from '../services/getFaceImage';
import { useTranslation } from 'react-i18next';

function FaceRecognitionComponent() {
  const { t } = useTranslation();
  const [uploadedImage, setUploadedImage] = useState(null);
  const [name, setName] = useState('');
  const [registeredFaces, setRegisteredFaces] = useState([]);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    fetchEnrolledFaces();
  }, []);

  const fetchEnrolledFaces = async () => {
    try {
      const data = await getEnrolledFaces();
      const facesWithImages = await Promise.all(
        data.enrolled_faces.map(async (face) => {
          const imageUrl = await getEnrolledFaceImage(face.face_id);
          return { ...face, imageUrl };
        })
      );
      setRegisteredFaces(facesWithImages);
    } catch (error) {
      console.error('Error fetching enrolled faces:', error);
      setError('Failed to fetch enrolled faces. Please try again.');
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setUploadedImage(e.target.result);
      reader.readAsDataURL(file);
    }
  };

  const handleRegister = async () => {
    if (uploadedImage && name) {
      try {
        const imageFile = dataURLtoFile(uploadedImage, 'face.jpg');
        await enrollFace(imageFile, name);
        fetchEnrolledFaces(); // Refresh the list of enrolled faces
        setUploadedImage(null);
        setName('');
        setError(null);
      } catch (error) {
        console.error('Error registering face:', error);
        setError(error.message || 'Failed to register face. Please try again.');
      }
    }
  };

  const handleRemoveFace = async (faceId) => {
    try {
      await removeFace(faceId);
      fetchEnrolledFaces(); // Refresh the list of enrolled faces
      setError(null);
    } catch (error) {
      console.error('Error removing face:', error);
      setError(error.message || 'Failed to remove face. Please try again.');
    }
  };

  // Helper function to convert data URL to File object
  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  return (
    <div className="face-recognition-container">
      {error && <div className="error-message">{error}</div>}
      <div className="preview-section">
        <div className="preview-image-container">
          <button className="upload-btn" onClick={() => fileInputRef.current.click()}>
             {t('uploadPhoto')} ↓
          </button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleImageUpload}
            accept="image/*"
          />
          {uploadedImage ? (
            <img src={uploadedImage} alt="Uploaded face" className="preview-image" />
          ) : (
            <div className="preview-image-placeholder"></div>
          )}
        </div>
        <div className="name-register-container">
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter name"
            className="name-input"
          />
          <button className="register-btn" onClick={handleRegister} disabled={!uploadedImage || !name}>
          {t('registerButton')}
          </button>
        </div>
      </div>
          <div className="registered-faces-section">
              <h2 className="registered-faces-title">{t('registeredFaces')}</h2>
              <div className="faces-grid">
                  {registeredFaces.map((face) => (
                      <div key={face.face_id} className="face-item">
                          {face.imageUrl ? (
                              <img src={face.imageUrl} alt={face.name} className="face-image" />
                          ) : (
                              <div className="face-image-placeholder"></div>
                          )}
                          <div className="face-info">
                              <p className="face-name">{face.name}</p>
                              <button className="remove-btn" onClick={() => handleRemoveFace(face.face_id)}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                      <polyline points="3 6 5 6 21 6"></polyline>
                                      <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                      <line x1="10" y1="11" x2="10" y2="17"></line>
                                      <line x1="14" y1="11" x2="14" y2="17"></line>
                                  </svg>
                              </button>
                          </div>
                      </div>
                  ))}
              </div>
          </div>
    </div>
  );
}

export default FaceRecognitionComponent;