// components/LanguageSwitcher.js
import {React, useEffect, useState} from 'react';
import '../styles/LanguageSwitcher.css';
import { useTranslation } from 'react-i18next';

function LanguageSwitcher() {
  const { i18n, t } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);

  useEffect(() => {
    // Update active language when i18n.language changes
    setActiveLanguage(i18n.language);
  }, [i18n.language]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);
  };

  return (
    <div className="language-switcher">
      <h3>{t('selectLanguage')}</h3>
      <div className="language-buttons">
        <button
          className={`language-button ${activeLanguage === 'az' ? 'active' : ''}`}
          onClick={() => changeLanguage('az')}
        >
          <div className="flag-container">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_187_219)">
              <path d="M0 7.22217H20V12.7777H0V7.22217Z" fill="#E00034"/>
              <path d="M17.7778 2.77783H2.22222C1.63285 2.77783 1.06762 3.01196 0.650874 3.42871C0.234126 3.84545 0 4.41068 0 5.00005L0 7.22228H20V5.00005C20 4.41068 19.7659 3.84545 19.3491 3.42871C18.9324 3.01196 18.3671 2.77783 17.7778 2.77783Z" fill="#0098C3"/>
              <path d="M9.91369 11.8517C9.60513 11.8517 9.30145 11.7746 9.03026 11.6274C8.75907 11.4802 8.52896 11.2676 8.36084 11.0089C8.19272 10.7501 8.09193 10.4535 8.06762 10.1459C8.04331 9.83829 8.09626 9.52949 8.22164 9.24755C8.34703 8.96562 8.54089 8.71949 8.7856 8.53153C9.0303 8.34357 9.3181 8.21974 9.62284 8.1713C9.92757 8.12286 10.2396 8.15134 10.5305 8.25415C10.8214 8.35697 11.0821 8.53085 11.2887 8.76001C11.0229 8.36491 10.6372 8.06564 10.1885 7.9063C9.73972 7.74696 9.25167 7.73598 8.79622 7.87498C8.34076 8.01398 7.94201 8.2956 7.65871 8.67835C7.37541 9.0611 7.22255 9.52473 7.22266 10.0009C7.22276 10.4771 7.37583 10.9407 7.6593 11.3233C7.94277 11.7059 8.34166 11.9874 8.79717 12.1262C9.25268 12.2649 9.74073 12.2538 10.1894 12.0942C10.6381 11.9347 11.0236 11.6352 11.2892 11.24C11.1158 11.4328 10.9036 11.5869 10.6666 11.6923C10.4296 11.7977 10.1731 11.852 9.91369 11.8517Z" fill="white"/>
              <path d="M13.1491 9.99884L12.4847 9.76328L12.7869 9.12661L12.1502 9.42939L11.9136 8.7655L11.678 9.42995L11.6764 9.42884L11.0408 9.12773L11.3441 9.76439L10.6797 10.0011L11.3441 10.2366L11.0419 10.8733L11.6758 10.5716L11.6786 10.5705L11.9152 11.2344L12.1508 10.5699L12.788 10.8722L12.4847 10.2355L13.1491 9.99884Z" fill="white"/>
              <path d="M2.22222 17.2223H17.7778C18.3671 17.2223 18.9324 16.9882 19.3491 16.5714C19.7659 16.1547 20 15.5894 20 15.0001V12.7778H0V15.0001C0 15.5894 0.234126 16.1547 0.650874 16.5714C1.06762 16.9882 1.63285 17.2223 2.22222 17.2223Z" fill="#00AE65"/>
            </g>
            <defs>
              <clipPath id="clip0_187_219">
                <rect width="20" height="20" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          </div>
          <span>{t('azerbaijani')}</span>
        </button>
        <button
          className={`language-button ${activeLanguage === 'en' ? 'active' : ''}`}
          onClick={() => changeLanguage('en')}
        >
          <div className="flag-container">
          <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="14" fill="#012169"/>
            <path d="M2.34375 0L9.96875 5.65625L17.5625 0H20V1.9375L12.5 7.5L20 13.0312V15H17.4688L10 9.40625L2.5625 15H0V13.0938L7.46875 7.5625L0 2.03125V0H2.34375Z" fill="white"/>
            <path d="M13.2188 8.78125L20 13.75V15L11.7812 8.78125H13.2188ZM7.5 9.40625L7.6875 10.5L1.71875 15H0L7.5 9.40625ZM20 0V0.09375L12.2188 5.96875L12.2812 4.59375L18.4375 0H20ZM0 0L7.46875 5.5H5.59375L0 1.3125V0Z" fill="#C8102E"/>
            <path d="M7.53125 0V15H12.5V0H7.53125ZM0 5V9.0625H20V5H0Z" fill="white"/>
            <path d="M0 6.03125V8.03125H20V6.03125H0ZM8.53125 0V15H11.5312V0H8.53125Z" fill="#C8102E"/>
          </svg>
          </div>
          <span>{t('english')}</span>
        </button>
        <button
          className={`language-button ${activeLanguage === 'ru' ? 'active' : ''}`}
          onClick={() => changeLanguage('ru')}
        >
          <div className="flag-container">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_187_234)">
              <path d="M20 15.0001C20 15.5894 19.7659 16.1547 19.3491 16.5714C18.9324 16.9882 18.3671 17.2223 17.7778 17.2223H2.22222C1.63285 17.2223 1.06762 16.9882 0.650874 16.5714C0.234126 16.1547 0 15.5894 0 15.0001V12.7778H20V15.0001Z" fill="#CE2028"/>
              <path d="M0 7.22217H20V12.7777H0V7.22217Z" fill="#22408C"/>
              <path d="M17.7778 2.77783H2.22222C1.63285 2.77783 1.06762 3.01196 0.650874 3.42871C0.234126 3.84545 0 4.41068 0 5.00005L0 7.22228H20V5.00005C20 4.41068 19.7659 3.84545 19.3491 3.42871C18.9324 3.01196 18.3671 2.77783 17.7778 2.77783Z" fill="#EEEEEE"/>
            </g>
            <defs>
              <clipPath id="clip0_187_234">
                <rect width="20" height="20" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          </div>
          <span>{t('russian')}</span>
        </button>
      </div>
    </div>
  );
}

export default LanguageSwitcher;