// components/CategorySelection.js
import React from 'react';
import { useTranslation } from 'react-i18next';


function CategorySelection({ onSelect, selectedCategory, disabled }) {
  const { t } = useTranslation(); 
  const categories = ['Filmlər', 'Verilişlər', 'Xəbərlər', 'Cizgi Filmləri', 'Konsertlər', 'İdman'];

  return (
    <div className={`category-modal ${disabled ? 'disabled' : ''}`}>
      <h2>{t('categoryModalHeader')}</h2>
      <div className="category-buttons">
        {categories.map(category => (
          <button
            key={category}
            onClick={() => !disabled && onSelect(category)}
            className={selectedCategory === category ? 'selected' : ''}
            disabled={disabled}
          >
            {t(`categories.${category}`)}
          </button>
        ))}
      </div>
    </div>
  );
}

export default CategorySelection;