const API_BASE_URL = process.env.REACT_APP_SEARCH_API_URL;

export const removeFace = async (faceId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/remove-face/${faceId}`, {
      method: 'DELETE',
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || 'Failed to remove face');
    }

    return await response.json();
  } catch (error) {
    console.error('Error removing face:', error);
    throw error;
  }
};