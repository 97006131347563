// components/DownloadButton.js
import React, { useState } from 'react';
import CustomAlert from './CustomAlert';
import '../styles/DownloadButton.css';
import { useTranslation } from 'react-i18next';


function DownloadButton({ videoData, filename, category }) {
  const { t } = useTranslation();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleDownload = () => {
    const downloadedVideos = JSON.parse(localStorage.getItem('downloadedVideos') || '[]');
    
    // Create a unique identifier for the video
    const videoIdentifier = `${filename}_${videoData.start_time}_${videoData.end_time}`;
    
    // Check if the video is already downloaded
    const isAlreadyDownloaded = downloadedVideos.some(
      video => `${video.filename}_${video.start_time}_${video.end_time}` === videoIdentifier
    );

    if (isAlreadyDownloaded) {
      setAlertMessage(t('downloadButton.alreadyDownloaded'));
      setShowAlert(true);
      return;
    }

    const videoToSave = {
      id: Date.now(), // unique identifier for the download entry
      filename,
      category,
      thumbnail_url: videoData.thumbnail_url,
      video_url: videoData.video_url,
      start_time: videoData.start_time,
      end_time: videoData.end_time,
      downloadDate: new Date().toISOString()
    };

    downloadedVideos.push(videoToSave);
    localStorage.setItem('downloadedVideos', JSON.stringify(downloadedVideos));
    
    setAlertMessage(t('downloadButton.downloadSuccess'));
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return (
    <>
      <button className="download-button" onClick={handleDownload}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.33331 14.1666V15.8333C3.33331 16.2753 3.50891 16.6992 3.82147 17.0118C4.13403 17.3243 4.55795 17.4999 4.99998 17.4999H15C15.442 17.4999 15.8659 17.3243 16.1785 17.0118C16.4911 16.6992 16.6666 16.2753 16.6666 15.8333V14.1666M5.83331 9.16659L9.99998 13.3333M9.99998 13.3333L14.1666 9.16659M9.99998 13.3333V3.33325" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
      </button>
      {showAlert && <CustomAlert message={alertMessage} onClose={handleCloseAlert} />}
    </>
  );
}

export default DownloadButton;