// pages/Landing.js
import React from 'react';
import Header from '../components/landing/header';
import Features from '../components/landing/features';
import Application from '../components/landing/application';
import Numbers from '../components/landing/numbers';
import Footer from '../components/landing/footer';
import Cta from '../components/landing/cta';

function Landing() {
  return (
    <>
      <Header />
      <div id="features">
        <Features />
      </div>
      <div id="applications">
        <Application />
      </div>
      <div id="highlights">
        <Numbers />
      </div>
      <Cta />
      <Footer />
    </>
  );
}

export default Landing;