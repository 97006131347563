import React, { useState, useRef, useEffect } from 'react';
import { handleFolderSelect, handleFileInputChange } from '../utils/fileHandlers';
import { uploadVideo } from '../services/uploadService';
import { addStream } from '../services/addStream';
import { checkAvailableStreams } from '../services/checkAvailableStreams';
import '../styles/Home.css';
import { useTranslation } from 'react-i18next';

function UploadSection({ category, resetTrigger, isProcessing, setIsProcessing }) {
  const { t } = useTranslation();
  const [status, setStatus] = useState('');
  const [isComplete, setIsComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [streamProcessing, setStreamProcessing] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    let intervalId;
    if (streamProcessing) {
      intervalId = setInterval(checkStreamStatus, 5000); // Check every 5 seconds
    }
    return () => clearInterval(intervalId);
  }, [streamProcessing]);

  useEffect(() => {
    setIsComplete(false);
    setIsProcessing(false);
    setIsLoading(false);
    setStreamProcessing(false);
    setStatus('');
    setYoutubeUrl('');
  }, [category, resetTrigger, setIsProcessing]);

  const handleSelection = async () => {
    if (isComplete || isProcessing) {
      return;
    }

    setIsProcessing(true);
    setIsLoading(true);
    const files = await handleFolderSelect(setStatus, fileInputRef);
    if (files && files.length > 0) {
      await processFiles(files);
    } else {
      setIsLoading(false);
      setIsProcessing(false);
    }
  };

  const handleFileSelection = (event) => {
      setIsComplete(false);
      setIsProcessing(true);
      setIsLoading(true);
      const files = handleFileInputChange(event, setStatus);
      if (files && files.length > 0) {
          processFiles(files);
      } else {
          setIsLoading(false);
          setIsProcessing(false);
      }
  };

  const processFiles = async (files) => {
      // setStatus('Uploading files...');
      for (const file of files) {
          await uploadFile(file);
      }
      // setStatus('All files uploaded. Starting stream processing...');
      setStreamProcessing(true);
  };

  const uploadFile = async (file) => {
    try {
        const uploadResponse = await uploadVideo(file, category);
        console.log('Video uploaded successfully:', uploadResponse);
        // setStatus(prevStatus => `${prevStatus}\nUploaded: ${file.name}`);
        await addStreamToProcess(uploadResponse.filename, uploadResponse.category);
    } catch (error) {
        console.error(`Error uploading file ${file.name}:`, error);
        setStatus(prevStatus => `${prevStatus}\nError uploading file ${file.name}: ${error.message}`);
    }
  };

  const handleYoutubeSubmit = async (event) => {
    event.preventDefault();
    if (!youtubeUrl) return;

    setIsProcessing(true);
    setIsLoading(true);
    try {
      // setStatus('Processing YouTube URL...');
      const uploadResponse = await uploadVideo(youtubeUrl, category);
      console.log('YouTube video processed successfully:', uploadResponse);
      // setStatus('YouTube video processed. Starting stream processing...');
      await addStreamToProcess(uploadResponse.filename, uploadResponse.category);
      setStreamProcessing(true);
    } catch (error) {
      console.error('Error processing YouTube URL:', error);
      setStatus(`Error processing YouTube URL: ${error.message}`);
      setIsLoading(false);
      setIsProcessing(false);
    }
  };

  const addStreamToProcess = async (filename, category) => {
    try {
        const streamResponse = await addStream(filename, category);

        console.log('Stream added successfully:', streamResponse);
        // setStatus(prevStatus => `${prevStatus}\nStream added for ${filename}`);
    } catch (error) {
        console.error(`Error adding stream for ${filename}:`, error);
        setStatus(prevStatus => `${prevStatus}\nError adding stream for ${filename}: ${error.message}`);
    }
  };

  const checkStreamStatus = async () => {
      try {
          const response = await checkAvailableStreams();
          console.log('Stream status:', response);
          if (response.available_streams === "8") {
              setStatus(prevStatus => `${prevStatus}\nAll processing complete. All streams available.`);
              setIsComplete(true);
              setIsProcessing(false);
              setIsLoading(false);
              setStreamProcessing(false);
          } else {
              setStatus(prevStatus => `${prevStatus}\nProcessing ongoing. Available streams: ${response.available_streams}`);
          }
      } catch (error) {
          console.error('Error checking stream status:', error);
          setStatus(prevStatus => `${prevStatus}\nError checking stream status: ${error.message}`);
          setIsProcessing(false);
          setIsLoading(false);
          setStreamProcessing(false);
      }
  };

  const renderIcon = () => {
    if (isComplete) {
      return (
        <div className="complete-icon">
          <svg width="80" height="80" viewBox="0 0 57 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 33.9999L6.33335 22.3332C5.03335 21.0332 2.96668 21.0332 1.66668 22.3332C0.366681 23.6332 0.366681 25.6999 1.66668 26.9999L15.6333 40.9666C16.9333 42.2666 19.0333 42.2666 20.3333 40.9666L55.6667 5.66657C56.9667 4.36657 56.9667 2.2999 55.6667 0.999902C54.3667 -0.300098 52.3 -0.300098 51 0.999902L18 33.9999Z" fill="url(#paint0_linear_98_405)"/>
            <defs>
            <linearGradient id="paint0_linear_98_405" x1="28.6667" y1="0.0249023" x2="28.6667" y2="41.9416" gradientUnits="userSpaceOnUse">
            <stop stop-color="#D94A4A"/>
            <stop offset="1" stop-color="#5080E5"/>
            </linearGradient>
            </defs>
          </svg>
          <p className="complete-text">{t('uploadSection.completed')}</p>
        </div>
      );
    } else if (isLoading) {
      return (
        <div className="loading-icon">
        <div className="spinner">
          <div className="circle circle1"></div>
          <div className="circle circle2"></div>
          <div className="circle circle3"></div>
          <div className="circle circle4"></div>
        </div>
      </div>
      );
    } else {
      return (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M81.25 12.5H18.75C15.2982 12.5 12.5 15.2982 12.5 18.75V81.25C12.5 84.7018 15.2982 87.5 18.75 87.5H81.25C84.7018 87.5 87.5 84.7018 87.5 81.25V18.75C87.5 15.2982 84.7018 12.5 81.25 12.5Z" stroke="#C0C0C0" strokeWidth="3" strokeLinejoin="round"/>
          <path d="M8.33337 64.5833H31.25L35.4167 72.9167H64.5834L68.75 64.5833H91.6667M87.5 75V54.1667M37.5 45.8333L50 58.3333M50 58.3333L62.5 45.8333M50 58.3333V25M12.5 75V54.1667" stroke="#C0C0C0" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      );
    }
  };

  return (
    <div className="download-section">
      <div className="icon-container" onClick={handleSelection}>
        {renderIcon()}
      </div>
      {!isComplete && (
        <>
          <p className="upload-text">
            <span className="category">{t(`categories.${category}`)}</span>
            <span className="instruction">
              {isLoading ? t('uploadSection.preparing') : t('uploadSection.selectFolder')}
            </span>
          </p>
          {status && <p className="status">{status}</p>}
          
          {/* Add YouTube URL input */}
          <form onSubmit={handleYoutubeSubmit} className="youtube-form">
            <input
              type="text"
              value={youtubeUrl}
              onChange={(e) => setYoutubeUrl(e.target.value)}
              placeholder={t('uploadYouTubeSection.enterYoutubeUrl')}
              className="youtube-input"
            />
            <button type="submit" className="youtube-submit">
              {t('uploadYouTubeSection.processYoutubeVideo')}
            </button>
          </form>
        </>
      )}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileSelection}
        accept="video/*"
        multiple
      />
    </div>
  );
}

export default UploadSection;