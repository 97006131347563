// pages/Home.js
import React, { useState, useEffect } from 'react';
import CategorySelection from '../components/CategorySelection';
import UploadSection from '../components/UploadSection';
import '../styles/Home.css';
import { useTranslation } from 'react-i18next';
import Sidebar from '../layouts/Sidebar';


function Home({ resetTrigger, isProcessing, setIsProcessing, setResetTrigger }) {
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleCategorySelect = (category) => {
    if (!isProcessing && category !== selectedCategory) {
      setSelectedCategory(category);
    }
  };

  useEffect(() => {
    setSelectedCategory(null);
  }, [resetTrigger]);

  const handleHomeClick = () => {
    if (!isProcessing) {
      setResetTrigger(prev => prev + 1);
    }
  };

  return (
    <div className='app'>
      <Sidebar onHomeClick={handleHomeClick} disabled={isProcessing} />
      <div className="main-content">
        <div className="home">
          {isProcessing && <div className="overlay"></div>}
          <header className="home-header">
            <h1>{t('homeHeader')}</h1>
          </header>
          <CategorySelection
            onSelect={handleCategorySelect}
            selectedCategory={selectedCategory}
            disabled={isProcessing}
          />
          {selectedCategory && (
            <UploadSection
              category={selectedCategory}
              resetTrigger={resetTrigger}
              isProcessing={isProcessing}
              setIsProcessing={setIsProcessing}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;