// components/LoginForm.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../services/authService';
import { useTranslation } from 'react-i18next';

function LoginForm({ onLoginSuccess }) {
  const { t } = useTranslation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await login(username, password);
      onLoginSuccess();
      navigate('/home');
    } catch (err) {
      setError(t('login.invalidCredentials'));
    }
  };

  return (
    <form className="mw-sm mx-auto" onSubmit={handleSubmit} >
      <div className="mb-4 row g-6">
        <div className="col-12">
          <div className="form-group">
            <label
              className="mb-1 fw-medium text-white"
              htmlFor="signInInput3-1"
            >
              {t("login.usernamePlaceholder")}
            </label>
            <input
              className="form-control text-secondary-dark shadow"
              required
              id="signInInput3-1"
              type="text"
              placeholder={t("login.usernamePlaceholder")}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
        </div>
        <div className="col-12">
          <div className="form-group">
            <label
              className="mb-1 fw-medium text-white"
              htmlFor="signInInput3-2"
            >
              {t("login.passwordPlaceholder")}
            </label>
            <input
              className="form-control text-secondary-dark shadow"
              required
              id="signInInput3-2"
              type="password"
              placeholder={t("login.passwordPlaceholder")}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
      </div>
      {error && <p className="error">{error}</p>}
      <div className="mb-6 row">
        <div className="col-12">
          <button
            className="btn btn-lg btn-success fs-11 py-3 w-100 text-success-light shadow"
            type="submit"
          >
            {t('login.loginButton')}
          </button>
        </div>
      </div>
    </form>
  );
}

export default LoginForm;